<template>
  <div class="content">
    <PageHeader title="Optouts" />
    <section>
      <button
          class="btn btn-success btn-add-optout"
          v-modal="{
            target: 'create-optouts-modal',
          }"
        >
          <i class="fas fa-plus"></i> Novo
      </button>
      <div class="config">
        <div class="btn-group">
          <button
            class="btn btn-primary"
            :class="{
              'qt-loader qt-loader-mini qt-loader-right': isDownloading,
            }"
            @click="exportOptout"
            v-bind:disabled="isDownloading"
          >
            <i class="fas fa-gear"></i> {{$t('generic-str.export')}}
          </button>
          <button
            class="btn btn-secondary"
            @click="callOptoutLogo"
            v-modal="{
              target: 'optout-logo-modal',
              data: { newlogo },
            }"
          >
            <i class="fas fa-gear"></i> {{$t('generic-str.logo')}}
          </button>
        </div>
      </div>
    </section>
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <opted-card />
        </div>
      </div>
    </section>
    <create-optouts-modal id="create-optouts-modal" @submit="fetchAll(1)" />
    <optouts-modal id="optouts-modal" @submit="fetchAll(1)" />
    <optout-setting-modal id="optout-setting-modal" />
    <optout-logo-modal id="optout-logo-modal" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import OptedCard from '@/components/mails/optouts/OptedCard.vue';
import OptoutSettingModal from '@/components/mails/OptoutSettingModal.vue';
import OptoutLogoModal from '@/components/mails/OptoutLogoModal.vue';
import CreateOptoutsModal from '@/components/mails/CreateOptoutModal.vue';
import OptoutsModal from '@/components/whatsapp/OptoutsModal.vue';
import SmsService from '@/services/sms.service';
import MailService from '@/services/mail.service';
import Swal from 'sweetalert2';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    OptedCard,
    CreateOptoutsModal,
    OptoutsModal,
    OptoutSettingModal,
    OptoutLogoModal,
  },
  data() {
    return {
      isSending: false,
      isDownloading: false,
      selectedTemplates: [],
      optoutSetting: {},
      newlogo: null,
    };
  },
  mounted() {
    MailService.getTrackingSettings({}).then(
      (response) => {
        if (response.id === null) {
          this.optoutSetting.html_content = '<p>Para não receber mais esse email clique <% aqui %></p>';
          this.optoutSetting.landing_page_html = '<p>TUDO CERTO, você foi removido da lista de emails</p>';
        } else {
          this.optoutSetting = response;
        }
      },
      (error) => {
        this.content = error;
      },
    );
  },
  methods: {
    callOptout() {
      this.$root.$emit('optout.modal.change', this.optoutSetting);
    },
    async exportOptout() {
      this.isDownloading = true;
      const name = 'optout.csv';

      MailService.exportOptout().then((blob) => {
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, name);
          this.isDownloading = false;
        } else {
          // feature detection
          // Browsers that support HTML5 download attribute
          blob = blob.slice(0, blob.size, 'application/octet-stream; charset=windows-1252');
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', name);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          this.isDownloading = false;
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          }, 1000);
        }
      }).catch((error) => {
        this.$toast.show({
          title: 'Optout',
          content: error,
          type: 'danger',
        });
      }).finally(() => {
        this.isDownloading = false;
      });
    },
    callOptoutLogo() {
      this.newlogo = Date.now();
      console.log('this.newlogo', this.newlogo);
    },
    fetchKeywords() {
      this.$refs.keywords.fetch(1);
    },
    selectedTemplatesChange(param) {
      this.selectedTemplates = param.selectedTemplates;
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('mail-component.remove'),
        text: this.$t('mail-component.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteAllOptouts({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.$root.$emit('updated.optout');
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}

.config {
  float: right;
  width: 265px;
  margin-right: 30px;
  text-align: right;
}
.btn-add-optout{
  margin-left: 28px;
}
</style>
