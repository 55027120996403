<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" v-if="data">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Configurar Opt-out</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <h3 class="control-label text-left col-md-12 text-left-m"
                  >Mensagem de Unsubscription</h3
                >
                <div class="col-md-12">
                  <email-input
                    v-model="data.html_content"
                  />
                </div>
              </div>
              <div class="form-group row">
                <h3 class="control-label text-left col-md-12 text-left-m"
                  >Página de sucesso</h3
                >
                <div class="col-md-12">
                  <email-input
                    v-model="data.landing_page_html"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Modal from '@/mixins/Modal';
import EmailInput from '@/components/form/EmailInput.vue';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    EmailInput,
  },
  data() {
    return {
      isSending: false,
      data: {},
      options: {
        format: 'YYYY-MM-DD HH:mm',
        useCurrent: false,
        keepInvalid: true,
      },
    };
  },
  mounted() {
    this.$root.$on('optout.modal.change', this.changeData);
  },
  methods: {
    changeData(data) {
      this.data = data;
    },
    save() {
      this.isSending = true;
      this.data.plain_content = null;
      this.data.replacement_tag = null;
      this.data.url = null;
      MailService.putTrackingSettings(this.data).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Dados atualizado',
            type: 'success',
          });
          this.$emit('submit');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
          console.log(error.response.data.message);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
